import React from 'react'
import { Button } from '@the-platform-group/component-library/Button'
import { deleteDomainExcludeRule } from 'services/analytics'
import { useMutation, useQueryClient } from 'react-query'
import { useNotification } from 'components/Notification'
import { useConfirmationDialog } from 'components/ConfirmationDialog'
import { Typography } from '@the-platform-group/component-library'
import { HIDABLE_WEBSITES_QUERY_KEY, HIDDEN_WEBSITES_QUERY_KEY } from './constants'

export const hiddenWebsitesTableColumns = [
  {
    field: 'domain',
    headerName: 'Domain Name',
    flex: 1,
    minWidth: 200,
    renderCell: params => {
      const node = params.api.getRowNode(params.id)
      // Only show data in top-level rows (depth 0)
      return node?.depth > 0 ? null : params.value
    },
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    flex: 0.7,
    minWidth: 150,
    renderCell: params => {
      const node = params.api.getRowNode(params.id)
      // Only show data in top-level rows (depth 0)
      return node?.depth > 0 ? null : params.value
    },
  },
  {
    field: 'actions',
    headerName: 'Action',
    width: 50,
    renderCell: params => {
      const node = params.api.getRowNode(params.id)
      // Only show data in top-level rows (depth 0)
      return node?.depth > 0 ? null : (
        <RestoreButton domainExcludeRuleId={params.row.domainExcludeRuleId} />
      )
    },
  },
]

const RestoreButton = ({ domainExcludeRuleId }) => {
  const queryClient = useQueryClient()
  const { createNotification } = useNotification()
  const { createConfirmationDialog } = useConfirmationDialog()

  const { mutate: excludeDomainMutate } = useMutation(data => deleteDomainExcludeRule(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([HIDABLE_WEBSITES_QUERY_KEY])
      queryClient.invalidateQueries([HIDDEN_WEBSITES_QUERY_KEY])
    },
    onError: error => {
      createNotification({ message: error.message, variant: 'error' })
    },
  })

  return (
    <Button
      variant="text"
      size="small"
      onClick={() => {
        createConfirmationDialog({
          title: 'Are you sure you want to restore?',
          body: (
            <>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Restored organizations will be re-added to all customer accounts, including their
                table views and summary emails. Organizations may be mapped to the same domain,
                restoring such an organization will restore all organizations with the same domain.
              </Typography>
              <Typography variant="body2">
                Please note: These changes apply globally to all customers, so ensure that you've
                selected the correct organization before proceeding.
              </Typography>
            </>
          ),
          confirmButtonText: 'Restore',
          onConfirm: () => {
            excludeDomainMutate(domainExcludeRuleId)
          },
        })
      }}
    >
      Restore
    </Button>
  )
}
