import React from 'react'
import { useFlag } from '@unleash/proxy-client-react'

import { Route, Redirect, Switch, useLocation } from 'react-router-dom'
import { Box } from '@the-platform-group/component-library'

import ImportsRouter from 'pages/Imports'
import CustomersComponent from 'pages/Accounts/CustomersComponent'
import CustomerAccountComponent from 'pages/CustomerAccount/CustomerAccountComponent'
import UsersComponent from 'pages/Users/UsersComponent'
import UserDetailComponent from 'pages/UserDetail/UserDetailComponent'
import OrganizationsRouter from 'pages/Organizations'
import UploadRequests from 'pages/IrwinAssistant/Requests'
import PeopleRouter from 'pages/People/PeopleRouter'
import { NoboRouter } from 'pages/Nobo/NoboRouter'

import MenuTopComponent from 'components/MenuTop/MenuTopComponent'
import MenuSideComponent from 'components/MenuSide/MenuSideComponent'
import ScrollToTop from 'components/ScrollToTop/ScrollToTop'
import { NotificationProvider } from 'components/Notification'
import { ConfirmationDialogProvider } from 'components/ConfirmationDialog'
import FactsetRouter from './pages/Factset/FactsetRouter'
import { useSessionState } from 'contexts/Session'
import { isAdmin, isAnalystSurveillance } from 'utils/auth'
import SurveillancePage from 'pages/Surveillance'
import ReportingGrants from 'pages/ReportingGrants'
import PrivateRoute from './PrivateRoute'
import NotFound from 'pages/NotFound'
import ForbiddenErrorBoundary from 'boundaries/ForbiddenErrorBoundary'
import NotFoundErrorBoundary from 'boundaries/NotFoundErrorBoundary'
import AffiliationPage from 'pages/Affiliations'
import DefinitionUpdatesModal from 'components/MarketCaps/DefinitionUpdatesModal'
import MergeProfiles from 'pages/MergeProfiles'
import IrwinAssistantSummaries from 'pages/IrwinAssistant/Summaries'
import IqHiddenWebsites from 'pages/IQ/HiddenWebsites'

const Layout = () => {
  const { currentUser } = useSessionState()
  const location = useLocation()

  return (
    <>
      <DefinitionUpdatesModal />
      <MenuTopComponent />
      {!isAnalystSurveillance(currentUser) && <MenuSideComponent />}
      {isAnalystSurveillance(currentUser) && <Redirect to="/surveillance" />}
      <NotificationProvider>
        <ConfirmationDialogProvider>
          <Box
            flexGrow={1}
            p={3}
            maxWidth={!isAnalystSurveillance(currentUser) ? 'calc(100% - 240px)' : 'unset'}
          >
            <Box sx={theme => theme.mixins.toolbar} />

            <ScrollToTop>
              <NotFoundErrorBoundary location={location}>
                <ForbiddenErrorBoundary location={location}>
                  <Switch>
                    <PrivateRoute exact path="/" />
                    {/* TODO we should extract out "roles" and components that match them*/}
                    {isAdmin(currentUser) && (
                      <PrivateRoute
                        path="/accounts/customers/:id"
                        component={CustomerAccountComponent}
                      />
                    )}
                    {isAdmin(currentUser) && (
                      <PrivateRoute path="/accounts/customers" component={CustomersComponent} />
                    )}
                    {isAdmin(currentUser) && (
                      <PrivateRoute path="/users/:id" component={UserDetailComponent} />
                    )}
                    {isAdmin(currentUser) && (
                      <PrivateRoute path="/users" component={UsersComponent} />
                    )}
                    {isAdmin(currentUser) && (
                      <PrivateRoute path="/reporting-grants" component={ReportingGrants} />
                    )}
                    {isAdmin(currentUser) && (
                      <PrivateRoute path="/irwin_assistant/requests" component={UploadRequests} />
                    )}
                    <PrivateRoute path="/imports/contacts" component={ImportsRouter} />
                    <PrivateRoute path="/affiliations" component={AffiliationPage} />
                    <PrivateRoute path="/merge-profiles" component={MergeProfiles} />
                    <PrivateRoute path="/platform/organizations" component={OrganizationsRouter} />
                    <PrivateRoute path="/platform/people" component={PeopleRouter} />
                    <PrivateRoute path="/factset" component={FactsetRouter} />
                    <PrivateRoute path="/nobo" component={NoboRouter} />

                    <Route path="/surveillance" component={SurveillancePage} />
                    {isAdmin(currentUser) && (
                      <PrivateRoute
                        path="/irwin_assistant/summaries"
                        component={IrwinAssistantSummaries}
                      />
                    )}
                    <PrivateRoute path="/iq/hidden-websites" component={IqHiddenWebsites} />
                    <Route exact path="/404" component={NotFound} />
                    <Route render={() => <Redirect to="/404" />} />
                  </Switch>
                </ForbiddenErrorBoundary>
              </NotFoundErrorBoundary>
            </ScrollToTop>
          </Box>
        </ConfirmationDialogProvider>
      </NotificationProvider>
    </>
  )
}

export default Layout
