import { useQuery } from 'react-query'
import { getDomainExcludeRulesList } from 'services/analytics'
import { getFullName } from '@the-platform-group/formatters/people'
import { HIDDEN_WEBSITES_QUERY_KEY } from '../utils/constants'

const useHiddenWebsiteRows = (orgSearchTerm, pagination) => {
  // Query for websites or domains based on view type
  const { data, isLoading } = useQuery(
    [HIDDEN_WEBSITES_QUERY_KEY, orgSearchTerm, pagination.page, pagination.pageSize],
    () => {
      const params = {
        pageOffset: pagination.page * pagination.pageSize,
        pageSize: pagination.pageSize,
        term: orgSearchTerm || undefined,
      }

      return getDomainExcludeRulesList(params)
    },
    {
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
      initialData: { data: [], meta: { item_count: 0 } },
      select: response => {
        const treeData = response.data.flatMap((row, rowIndex) => {
          const orgParentId = row.organizations?.[0].id //first org is the parent org for this row

          return row.organizations.flatMap((org, orgIndex) => {
            const isParent = orgIndex === 0
            org.path = []
            org.path.push(orgParentId)

            if (!isParent) {
              org.path.push(org.id)
            }

            return {
              id: `${rowIndex}-${orgIndex}`,
              domainExcludeRuleId: row.id,
              domain: row.domain,
              organizationName: org.name,
              organizationPath: org.path,
              createdBy: getFullName(row.creator),
            }
          })
        })

        return {
          rows: treeData || [],
          totalItemCount: response.meta.item_count || 0,
        }
      },
    },
  )

  return {
    rows: data?.rows,
    totalItemCount: data?.totalItemCount,
    isLoading,
  }
}

export default useHiddenWebsiteRows
