import { toSnake, toCamel } from 'change-object-case'
import { client, deserializer } from 'services/client'

/**
 * Fetches Global IQ Domain Exclude Rules List
 * @param {object} params - an object that expects the customer siteId
 * @returns {object} - an array of excluded domains
 */
export async function getDomainExcludeRulesList(params) {
  const response = await client().get(`v2/analytics/domain_exclude_rules/global`, {
    params: toSnake(params),
  })

  return { data: deserializer.deserialize(response), meta: toCamel(response.meta) }
}

/**
 * Creates a new Global Domain Exclude Rule for a customer's IQ Insights
 * @param {object} params - an object that expects the customer siteId, domain, and reason
 * @returns {object} - the newly created exclude rule
 */
export async function createDomainExcludeRule(params) {
  const response = await client().post(`v2/analytics/domain_exclude_rules/global`, toSnake(params))

  return { data: deserializer.deserialize(response) }
}

/**
 * Removes a Domain Exclude Rule for a customer's IQ Insights
 * @param {string} id - the id of the exclude rule to remove
 * @returns {object} - the removed exclude rule
 */
export async function deleteDomainExcludeRule(id) {
  return await client().delete(`v2/analytics/domain_exclude_rules/${id}`)
}

/**
 * Removes multiple Domain Exclude Rules for a customer's IQ Insights
 * @param {string[]} ids - array of exclude rule ids to remove
 * @returns {object} - the API response
 */
export async function bulkDeleteDomainExcludeRules(ids) {
  return await client().delete('v2/analytics/domain_exclude_rules/bulk_destroy', {
    data: { ids },
  })
}
