import React, { useMemo } from 'react'
import { formatDate } from '@the-platform-group/formatters/dateFormat'

import { Button } from '@the-platform-group/component-library/Button'
import { Grid } from '@the-platform-group/component-library/Layout'
import {
  Checkbox,
  FormControlLabel,
  TypeaheadSingleSelect,
} from '@the-platform-group/component-library'

import { useSurveillanceContext } from '../hooks/SurveillanceContext'

import { datasources } from 'pages/ReportingGrants/transforms/datasources'
import { isAdmin } from 'utils/auth'
import { useSessionState } from 'contexts/Session'
import SearchBar from './SearchBar'
import { DatePicker } from '@the-platform-group/component-library'
import { shouldDisableReportMonth } from '../utils/reports'
import { MONTH_YEAR_FORMAT } from 'constants/formats'
import { DATE_FORMAT } from '@the-platform-group/formatters/constants'

const MIN_REPORT_DATE = new Date(2020, 0, 1)
const MAX_REPORT_DATE = new Date(new Date().getFullYear(), 11, 31)

function Actions({ isLoading, hasHolders, hasReport }) {
  const { currentUser } = useSessionState()
  const {
    selectedSecurity,
    includeZeroedHolders,
    handleChangeSecurity,
    toggleIncludeZeroedHolders,
    handleNewHolderModal,
    handleNewReportModal,
    selectedDateByMonth,
    setSelectedDateByMonth,
    reports,
    setReportsDateRange,
  } = useSurveillanceContext()

  const securitiesOptions = useMemo(
    () =>
      (currentUser.reportingGrants || []).map(grant => ({
        id: grant.id,
        customer: {
          id: grant.customerId,
          name: grant.customerName,
        },
        user: currentUser,
        security: {
          id: grant.securityId,
          name: grant.securityName,
          ticker: grant.securityTicker,
        },
        datasource: grant.datasource,
      })),
    [currentUser],
  )

  const availableReportsInYear = useMemo(() => {
    return reports.reduce((acc, { date }) => {
      const monthString = formatDate(date, MONTH_YEAR_FORMAT)

      if (!acc[monthString]) {
        acc[monthString] = true
      }

      return acc
    }, {})
  }, [reports])

  return (
    <Grid
      item
      container
      xs={12}
      justifyContent="space-between"
      sx={{
        mb: 1,
        height: 61,
      }}
    >
      <Grid item container xs={4}>
        <Grid item xs={12}>
          <TypeaheadSingleSelect
            placeholder="Search for security"
            onChange={(_, value) => handleChangeSecurity(value)}
            value={selectedSecurity}
            variant="outlined"
            margin="none"
            options={securitiesOptions.filter(Boolean)}
            getOptionLabel={({ option: { security, customer, datasource } }) => {
              if (isAdmin(currentUser)) {
                return `${customer.name} / ${security.name} (${datasources[datasource]})`
              }

              return `${customer.name} / ${security.name}`
            }}
          />
        </Grid>
        {selectedSecurity.customer && (
          <Grid item container direction="row" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox checked={includeZeroedHolders} onChange={toggleIncludeZeroedHolders} />
              }
              label="Include Zero Value Holders"
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs={3}>
        {selectedSecurity.customer && <SearchBar isLoading={isLoading} />}
      </Grid>

      <Grid item container direction="row" justifyContent="flex-end" alignItems="flex-start" xs={5}>
        {hasHolders && (
          <Grid
            item
            xs={4}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <DatePicker
              value={selectedDateByMonth}
              onChange={date => {
                /**
                 * Check if the date is null which will update SurveillanceContext's selectedDateByMonth to null and reset the date range
                 * Check if the date is a valid date object, this required because user can enter a string in the input field and the date picker will try to parse it as a date object which will result in an invalid date object error
                 */
                if (date === null || (date instanceof Date && !isNaN(date))) {
                  setSelectedDateByMonth(date)
                }
              }}
              views={['year', 'month']}
              openTo="month"
              minDate={MIN_REPORT_DATE}
              maxDate={MAX_REPORT_DATE}
              disableFuture
              margin="none"
              variant="outlined"
              inputFormat={MONTH_YEAR_FORMAT}
              clearable
              shouldDisableMonth={date => shouldDisableReportMonth(date, availableReportsInYear)}
              onYearChange={date => {
                const selectedYear = date.getFullYear()

                const firstDayOfYear = new Date(selectedYear, 0, 1)
                const lastDayOfYear = new Date(selectedYear, 11, 31)

                setReportsDateRange({
                  from: formatDate(firstDayOfYear, DATE_FORMAT),
                  to: formatDate(lastDayOfYear, DATE_FORMAT),
                })
              }}
              PopperProps={{
                sx: {
                  // Default style from component-library we override PopperProps > sx to add styles for month buttons therefore we need to add the default styles here
                  '& .PrivatePickersSlideTransition-root': {
                    minHeight: 230,
                  },
                  // Style for the available month buttons, default style is not enough for distinct from the disabled ones
                  '& .MuiPickersMonth-monthButton:not(:disabled):not(.Mui-selected)': {
                    border: '1px solid',
                    borderColor: 'primary.dark',
                    '&:hover': {
                      bgcolor: 'primary.dark',
                      color: 'primary.contrastText',
                    },
                  },
                  // Opacity for disabled months
                  '& .MuiPickersMonth-monthButton:disabled': {
                    opacity: 0.5,
                    color: 'text.disabled',
                  },
                  // Style for the selected and disabled months, this one is happening when changing the year, MUI automatically pick a month from the new year but that month can be disabled, and since its picked as default it has selected class and disabled class. We need to override the style of the selected and disabled months with this style
                  '& .MuiPickersMonth-monthButton.Mui-selected:disabled': {
                    bgcolor: 'transparent',
                  },
                },
              }}
            />
          </Grid>
        )}
        {hasHolders && (
          <>
            <Button
              disabled={isLoading || !hasReport}
              onClick={() => handleNewHolderModal(true)}
              sx={{
                ml: 1,
              }}
            >
              + add holder
            </Button>
            <Button
              onClick={() => handleNewReportModal(true)}
              sx={{
                ml: 1,
              }}
              disabled={isLoading}
            >
              + add report
            </Button>
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default Actions
