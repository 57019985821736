import Jsona, { SwitchCaseJsonMapper } from 'jsona'
import { irwin } from 'config'
import { httpClient } from './httpClient'
import { cancellableQuery } from './utils'
import { snakeCase } from 'change-case-object'

const deserializer = new Jsona({
  jsonPropertiesMapper: new SwitchCaseJsonMapper(),
})

const deserializerAndCase = new Jsona({
  jsonPropertiesMapper: new SwitchCaseJsonMapper({ switchChar: '_' }),
})

function client() {
  return httpClient(irwin.baseUrl)
}

async function getPeople(pageOffset, pageSize, term, options) {
  const params = {
    page_offset: pageOffset,
    page_size: pageSize,
    ...(term ? { term } : {}),
    ...options,
  }
  const response = await client().get('v1/platform_people', { params })

  return {
    data: deserializer.deserialize(response),
    meta: response.meta,
  }
}

async function savePerson(person) {
  return person.id ? updatePerson(person) : createPerson(person)
}

async function createPerson(person) {
  const response = await client().post('v1/platform_people', person)

  return {
    data: deserializer.deserialize(response),
    meta: response.meta,
  }
}

async function updatePerson(person) {
  const response = await client().patch(`v1/platform_people/${person.id}`, person)

  return {
    data: deserializer.deserialize(response),
    meta: response.meta,
  }
}

async function approvePerson(id) {
  const response = await client().patch(`v1/platform_people/${id}/approve`, id)

  return {
    data: deserializer.deserialize(response),
    meta: response.meta,
  }
}

async function getPlatformPerson(id) {
  const response = await client().get(`v1/platform_people/${id}`)

  return {
    data: deserializer.deserialize(response),
  }
}

async function deletePerson(id) {
  const response = await client().delete(`v1/platform_people/${id}`, { id })
  return response
}

async function getPerson(id) {
  const response = await client().get(`v2/people/${id}`)

  return {
    data: deserializerAndCase.deserialize(response),
  }
}

const searchPeopleQuery = async (params, signal) => {
  const response = await client().get('/v2/people/search', {
    params,
    signal,
  })

  return {
    data: deserializerAndCase.deserialize(response),
  }
}

async function searchPeople(params) {
  return cancellableQuery(searchPeopleQuery, params)
}

const searchPeopleAdminQuery = async (params, signal) => {
  const response = await client().get('/v2/people/admin_search', {
    params,
    signal,
  })

  return {
    data: deserializerAndCase.deserialize(response),
  }
}

async function searchPeopleAdmin(params) {
  return cancellableQuery(searchPeopleAdminQuery, snakeCase(params))
}

const getPeopleProfilePictures = async peopleIds => {
  const response = await client().get('v2/people/profile_images', {
    params: { people_ids: peopleIds },
  })
  return deserializerAndCase.deserialize(response)
}

async function updatePersonSettings(personId, settings) {
  const response = await client().patch(`v2/people/${personId}/settings`, snakeCase(settings))
  return deserializerAndCase.deserialize(response)
}

export {
  savePerson,
  getPeople,
  getPlatformPerson,
  deletePerson,
  approvePerson,
  getPerson,
  searchPeople,
  searchPeopleAdmin,
  getPeopleProfilePictures,
  updatePersonSettings,
}
