import { useState, useEffect } from 'react'
import changeCase from 'change-case-object'
import queryString from 'query-string'
import transform_linkedin_url from 'linkedin-canonical-url'
// Services
import { savePerson } from 'services/people'
import { getOrganization } from 'services/organizations'
// Transforms
import { formatFocus } from 'transforms/focus'
import { formatAddress } from 'transforms/address'
// Hooks
import { useNotification } from 'components/Notification'
import { isEmpty } from '@the-platform-group/formatters/checks'
import { MARKET_CAP_ERROR_FIELD } from 'constants/marketCaps'
import { createInternalNote } from 'services/internalNotes'
import INTERNAL_NOTE_TYPES from 'constants/internalNoteTypes'

const usePersonCreateForm = history => {
  const { organizationId } = queryString.parse(history.location.search)
  const [person, setPerson] = useState({ ...buildDefaultPerson({}) })
  const [isCreatingPerson, setIsCreatingPerson] = useState(false)
  const { createNotification } = useNotification()

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const { data: organization } = await getOrganization(organizationId)
        setPerson(buildDefaultPerson(organization))
      } catch (error) {
        setPerson(() => {
          throw error
        })
      }
    }

    if (organizationId) {
      fetchOrganization()
    }
  }, [organizationId])

  const handlePersonChange = ({ target }) => {
    setPerson({ ...person, [target.name]: target.value })
  }

  const handlePersonCreate = async ({
    job,
    focuses,
    organization,
    personSocialProfiles,
    internalNoteContent,
  }) => {
    setIsCreatingPerson(true)
    const { address, delegateAddress, ...editedJob } = job
    const addressAttributes = delegateAddress
      ? { address_id: null }
      : { address_attributes: formatAddress(address) }

    const focusAttributes = formatFocus(focuses)
    const { relationshipNames, ...rest } = editedJob
    const jobAttributes = changeCase.snake({
      ...rest,
      organizationId: organization.id,
      personId: person.id,
    })
    const jobParams = {
      ...addressAttributes,
      job_focuses_attributes: focusAttributes,
      ...jobAttributes,
    }

    try {
      const personSocialProfilesParams = personSocialProfiles.map(psp => {
        const data = psp
        data.url = psp.url ? transform_linkedin_url(psp.url.trim()) : ''
        return data
      })
      const personParams = {
        ...changeCase.snake(person),
        jobs_attributes: [jobParams],
        person_social_profiles_attributes: personSocialProfilesParams,
      }

      const { data: newPerson } = await savePerson(personParams)
      if (internalNoteContent) {
        // only send request if there is content
        await createInternalNote(
          internalNoteContent,
          newPerson.id,
          INTERNAL_NOTE_TYPES.platformPerson,
        )
      }
      createNotification({ variant: 'success', message: 'Person was saved' })
      history.push(`/platform/people/${newPerson.id}/edit`)
    } catch (e) {
      // handle 401s and 403s in error boundary
      if (e.status === 401 || e.status === 403) {
        setIsCreatingPerson(() => {
          throw e
        })
        return
      }

      // intentionally throw on market cap definition changes,
      // these errors will be handled by an error boundary that will
      // refresh the values on the page
      if (e.response?.data?.errors?.map(e => e.field).includes(MARKET_CAP_ERROR_FIELD)) {
        setIsCreatingPerson(() => {
          throw e
        })
        return
      }

      const data = Object.keys(e.response?.data || {})
      const errors = data.map(err => `${err}: ${e.response?.data[err]}`).join(', ')

      const message = isEmpty(errors) ? 'Person can not save' : errors

      createNotification({ message, variant: 'error' })
    } finally {
      setIsCreatingPerson(false)
    }
  }

  return {
    isCreatingPerson,
    person,
    handlePersonChange,
    handlePersonCreate,
  }
}

function buildDefaultPerson(organization = {}) {
  return {
    id: null,
    firstName: '',
    lastName: '',
    bio: '',
    job: { focuses: [], organization },
    personSocialProfiles: [],
  }
}

export { usePersonCreateForm }
