import { useState } from 'react'
import { useQuery } from 'react-query'
import { Button, ProfilePicture, AvatarContainer } from '@the-platform-group/component-library'
import { AccountCircle as AccountCircleIcon } from '@the-platform-group/component-library/Icons'
import { getPeopleProfilePictures } from 'services/people'

/**
 * A container component that fetches and displays a person's LinkedIn profile picture
 * @param {Object} props - Component props
 * @param {Object} props.person - Person object containing id, firstName and lastName
 * @param {string} props.person.id - Unique identifier for the person
 * @param {string} props.person.firstName - Person's first name
 * @param {string} props.person.lastName - Person's last name
 * @param {('default'|'small'|'medium'|'large')} [props.size='default'] - Size variant for the profile picture
 * @returns {React.Component|null} ProfilePicture component or null if no person.id
 */
function ProfilePictureContainer({ person = {}, size = 'default' }) {
  const [shouldLoadProfilePicture, setShouldLoadProfilePicture] = useState(false)
  const cacheDuration = 1000 * 60 * 30 // 30 minutes
  const { data, isFetching, refetch, error } = useQuery({
    queryKey: ['peopleProfilePicture', person.id],
    queryFn: () => getPeopleProfilePictures([person.id]),
    enabled: !!person.id && shouldLoadProfilePicture,
    retry: false,
    cacheTime: cacheDuration,
    staleTime: cacheDuration,
    useErrorBoundary: error => {
      const status = error?.response?.status
      return !(status === 429 || status === 503) // manually handle 429 and 503 errors, otherwise throw error to boundary
    },
  })

  if (!person.id) {
    return null
  }

  if (shouldLoadProfilePicture) {
    return (
      <ProfilePicture
        error={error?.response?.status === 503 && error?.response?.data.errors[0].message}
        firstName={person.firstName}
        lastName={person.lastName}
        isLoading={isFetching}
        profilePictureSrc={data?.[0]?.url}
        reloadImage={refetch}
        size={size}
      />
    )
  }

  return (
    <AvatarContainer>
      <Button
        onClick={() => setShouldLoadProfilePicture(!shouldLoadProfilePicture)}
        variant="outlined"
        sx={{
          borderRadius: 20,
          flexDirection: 'column',
          fontSize: '0.7rem',
          width: '100%',
          height: '100%',
        }}
      >
        <AccountCircleIcon />
        Preview
      </Button>
    </AvatarContainer>
  )
}

export default ProfilePictureContainer
