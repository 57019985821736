import { useQuery } from 'react-query'
import { getOrganizationsWithDomains } from 'services/organizations'
import { HIDABLE_WEBSITES_QUERY_KEY } from '../utils/constants'

const useHidableWebsiteRows = (orgSearchTerm, pagination) => {
  // Query for websites or domains based on view type
  const { data: hidableWebsitesData, isLoading } = useQuery(
    [HIDABLE_WEBSITES_QUERY_KEY, orgSearchTerm, pagination.page, pagination.pageSize],
    () => {
      const params = {
        exclude_nil_types: true,
        include_global_domain_exclude_rules: true,
        with_domain_only: true,
        page_offset: pagination.page * pagination.pageSize,
        page_size: pagination.pageSize,
        ...(orgSearchTerm ? { term: orgSearchTerm } : {}),
      }

      return getOrganizationsWithDomains(params)
    },
    {
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
      initialData: { data: [], meta: { item_count: 0 } },
      select: data => ({
        data: data?.data || [],
        meta: {
          ...data?.meta,
          item_count: data?.meta?.item_count || 0,
        },
      }),
    },
  )

  return {
    rows: hidableWebsitesData?.data,
    totalItemCount: hidableWebsitesData?.meta?.item_count,
    isLoading,
  }
}

export default useHidableWebsiteRows
