import React, { Fragment, useState, useContext } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  RawButton as Button,
} from '@the-platform-group/component-library'

const initialState = {
  open: false,
  title: '',
  body: '',
  onConfirm: () => {},
}

const ConfirmationDialogContext = React.createContext({
  createConfirmationDialog() {},
})

function ConfirmationDialogProvider({ children }) {
  const [state, setState] = useState(initialState)
  const createConfirmationDialog = ({ title, body, onConfirm, confirmButtonText }) => {
    setState({ open: true, title, body, onConfirm, confirmButtonText })
  }
  const handleClose = () => setState(initialState)

  return (
    <ConfirmationDialogContext.Provider value={{ createConfirmationDialog }}>
      <ConfirmationDialogComponent {...state} onClose={handleClose} />
      {children}
    </ConfirmationDialogContext.Provider>
  )
}

const useConfirmationDialog = () => {
  const { createConfirmationDialog } = useContext(ConfirmationDialogContext)
  return {
    createConfirmationDialog,
  }
}

const ConfirmationDialogComponent = ({
  open,
  title,
  body,
  onConfirm,
  confirmButtonText = 'Confirm',
  onClose,
}) => {
  return (
    <Fragment>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{body}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={() => {
              onConfirm()
              onClose()
            }}
            variant="contained"
          >
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export { ConfirmationDialogProvider, useConfirmationDialog }
