import React from 'react'
import { debouncedLoadCustomers } from 'utils/optionsLoaders'
import { useSessionState } from 'contexts/Session'
import { humanizeSnakeCase } from 'utils/format'
import {
  INITIAL_SORT_STATE,
  UPLOAD_REQUEST_TABLE_NAME,
  UPLOAD_STATUS,
  UPLOAD_STATUS_STYLES,
} from './utils/constants'

import {
  Grid,
  Box,
  Typography,
  TypeaheadSingleSelect,
  TextField,
} from '@the-platform-group/component-library'
import { DataGrid } from '@the-platform-group/component-library/DataGrid'
import { AsyncSelect } from '@the-platform-group/component-library/Select'

import { handleSortModelChange } from 'components/Table/utils'

import FilePathTable from './components/FilePathTable'
import { getColumnValues, getRowValues } from './utils/getTableValues'
import useUploadRequests from 'pages/IrwinAssistant/Requests/hooks/useUploadRequests'

const UploadRequests = () => {
  const {
    page,
    uploadRequests,
    itemCount,
    isFetching,
    queryParams,
    handleOnSort,
    handleRowChange,
    handleFileDownload,
    handlePageChange,
    handlePageSizeChange,
    handleCustomerChange,
    handleStatusChange,
    updateUserUploadRequest,
  } = useUploadRequests()
  const { pageSize, customer, status } = queryParams

  const { currentUser } = useSessionState()

  return (
    <>
      <Typography variant="h4">Irwin Assistant Requests</Typography>
      <Box>
        <Grid container spacing={2} alignItems="center">
          <Grid item md={6} xs={12}>
            <AsyncSelect
              fullWidth
              hideArrow
              includeClearAll
              value={customer || ''}
              variant="outlined"
              placeholder="Search customers"
              onChange={handleCustomerChange}
              optionsLoader={term => debouncedLoadCustomers({ term })}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TypeaheadSingleSelect
              fullWidth
              includeClearAll
              variant="outlined"
              options={UPLOAD_STATUS}
              getOptionLabel={({ option }) => humanizeSnakeCase(option.status)}
              renderInput={params => <TextField {...params} label="Filter by status" />}
              onChange={(_, param) => handleStatusChange(param)}
              value={{ status }}
              isOptionEqualToValue={(option, value) => option.status === value.status}
            />
          </Grid>
        </Grid>
        <DataGrid
          name={UPLOAD_REQUEST_TABLE_NAME}
          rows={getRowValues(uploadRequests)}
          columns={getColumnValues({ currentUser, handleRowChange, updateUserUploadRequest })}
          sx={{ ...UPLOAD_STATUS_STYLES }}
          loading={isFetching}
          getRowHeight={obj => obj.densityFactor * 120}
          onSortModelChange={sortModel => handleSortModelChange(sortModel, handleOnSort, false)}
          initialState={{
            sorting: {
              sortModel: [INITIAL_SORT_STATE],
            },
          }}
          getDetailPanelHeight={() => 'auto'}
          getDetailPanelContent={({ row }) => (
            <FilePathTable row={row} onFileDownload={handleFileDownload} />
          )}
          // cell editing
          processRowUpdate={handleRowChange}
          // pagination
          rowCount={itemCount}
          pageSize={pageSize}
          page={page}
          pageSizeOptions={[50, 100, 150, 200]}
          onPageSizeChange={handlePageSizeChange}
          onPageChange={handlePageChange}
        />
      </Box>
    </>
  )
}

export default UploadRequests
