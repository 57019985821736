import { GridOverlay } from '@the-platform-group/component-library/DataGrid'
import { Box, Typography } from '@the-platform-group/component-library'

/**
 * Component to render when search has no matches found.
 * @param {object} props Object props
 * @param {string} props.term search field input value
 * @returns {NoMatchesFound} React component
 */
export const NoMatchesFound = ({ term }) => (
  <Box textAlign="center" py={2} color="secondary.dark">
    <Typography>No matches found for </Typography>
    <Typography sx={{ fontWeight: 'fontWeightBold' }}>{term}</Typography>
  </Box>
)

/**
 * Component to render when Search doesn't have any matches. The NoMatchesFound component
 * is wrapped in DataGrid's GridOverlay to override "no results" component
 * @param {object} props Object props
 * @returns {NoMatchesFoundOverlay} React component
 */
export const NoMatchesFoundOverlay = props => {
  return (
    <GridOverlay>
      <NoMatchesFound {...props} />
    </GridOverlay>
  )
}
