import { useState, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react'

// Tables
import {
  Table,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableBody,
  TableRow,
} from '@the-platform-group/component-library/Table'
import { Code as CodeIcon, Edit as EditIcon } from '@the-platform-group/component-library/Icons'

// Components
import { Typography, IconButton, Tooltip } from '@the-platform-group/component-library'
import { getSites } from 'services/customerWebsites'
import { formatDate } from '@the-platform-group/formatters/dateFormat'

const SiteTable = forwardRef((props, ref) => {
  const fetchData = useCallback(async () => {
    const value = await getSites({ customerId: props.customerId })
    setSiteCollection(value.data)
  }, [props.customerId])

  //allows us to call the fetchData method from a ref
  useImperativeHandle(
    ref,
    () => ({
      fetchData,
    }),
    [fetchData],
  )
  const [siteCollection, setSiteCollection] = useState([])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeaderCell>Site Id</TableHeaderCell>
          <TableHeaderCell>Name (URL)</TableHeaderCell>
          <TableHeaderCell>Security</TableHeaderCell>
          <TableHeaderCell>Snippet</TableHeaderCell>
          <TableHeaderCell>Created</TableHeaderCell>
          <TableHeaderCell>Options</TableHeaderCell>
        </TableRow>
      </TableHead>

      <TableBody
        columns={7}
        isLoading={!siteCollection}
        isEmpty={!siteCollection?.length}
        renderEmpty={<Typography>This customer currently has no sites provisioned</Typography>}
      >
        {siteCollection?.map(site => (
          <TableRow key={site?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              {site?.id}
            </TableCell>
            <TableCell>{site?.name}</TableCell>
            <TableCell>
              {site?.security
                ? `${site.security.name} ${site.security.listingPrimary?.tickerExchange}`
                : ''}
            </TableCell>
            <TableCell>
              <Tooltip title="View Code Snippet">
                <IconButton aria-label="View Code Snippet" onClick={() => props.showSnippet(site)}>
                  <CodeIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell>{formatDate(new Date(site?.createdAt), 'PP')}</TableCell>
            <TableCell>
              <Tooltip title="Edit Site Details">
                <IconButton aria-label="Edit Site Details" onClick={() => props.showEditSite(site)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
})

export default SiteTable
