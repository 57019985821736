import { useState } from 'react'
import { useMutation } from 'react-query'
import { updatePersonSettings } from 'services/people'

const usePersonSettings = ({ personId }) => {
  const [isProfilePictureEnabled, setIsProfilePictureEnabled] = useState(true)

  const updatePersonSettingsMutation = useMutation(() =>
    updatePersonSettings(personId, { profileImage: isProfilePictureEnabled }),
  )

  return {
    handleProfilePictureToggle: setIsProfilePictureEnabled,
    updatePersonSettingsMutation,
  }
}

export default usePersonSettings
