import React, { useState } from 'react'
import {
  Grid,
  Box,
  Typography,
  ToggleButtons,
  TextField,
} from '@the-platform-group/component-library'
import { VIEW_OPTIONS } from './utils/constants'
import HidableWebsitesTable from './HidableWebsitesTable'
import HiddenWebsitesTable from './HiddenWebsitesTable'

const UploadRequests = () => {
  const [orgSearchTerm, setOrgSearchTerm] = useState('')
  const [viewType, setViewType] = useState(VIEW_OPTIONS.allOrganizations)

  return (
    <>
      <Typography variant="h4">IQ Hidden Organizations</Typography>
      <Grid container sx={{ my: 1 }}>
        <Grid item xs={9} sx={{ p: 1, borderRadius: 1, backgroundColor: 'secondary.light' }}>
          <Typography>
            IQ Hidden Organizations lets you remove organizations from all customer accounts. You
            can easily restore them at any time. Organizations may be mapped to the same domain,
            hiding such an organization will remove all organizations with the same domain. Please
            review before hiding & restoring organizations.
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Please note: These changes apply globally to all customers, so ensure that you've
            selected the correct organization before proceeding.
          </Typography>
        </Grid>
      </Grid>
      <Box>
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{ my: 1 }}
          justifyContent="space-between"
        >
          <Grid item xs={4}>
            <TextField
              label="Search by Organization"
              size="small"
              fullWidth
              value={orgSearchTerm}
              onChange={e => setOrgSearchTerm(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <ToggleButtons
              options={Object.values(VIEW_OPTIONS)}
              selectedOption={viewType}
              onToggle={setViewType}
              fullWidth
              buttonStyles={{ '&': { width: '100%' } }}
            />
          </Grid>
        </Grid>
        {viewType === VIEW_OPTIONS.allOrganizations ? (
          <HidableWebsitesTable orgSearchTerm={orgSearchTerm} />
        ) : (
          <HiddenWebsitesTable orgSearchTerm={orgSearchTerm} />
        )}
      </Box>
    </>
  )
}

export default UploadRequests
