import React from 'react'
import PropTypes from 'prop-types'

import SOCIAL_PROFILES from 'constants/socialProfiles'
import PlatformTable from 'shared/PlatformTable'

const linkedInKey = 'linked_in'

const PEOPLE_COLUMNS = [
  { field: 'firstName' },
  { field: 'lastName' },
  { field: 'primaryOrg', headerName: 'Organization' },
  { field: 'biography' },
  { field: 'email', headerName: 'Email Address' },
  { field: 'linkedIn', headerName: SOCIAL_PROFILES[linkedInKey] },
  { field: 'approvedAt' },
  { field: 'actions' },
]

const transformRowData = (people, deletedPeopleIds) =>
  people.reduce((res, person) => {
    if (!deletedPeopleIds.includes(person.id)) {
      const {
        id,
        firstName,
        lastName,
        bio: biography,
        jobs = [],
        personSocialProfiles = [],
        approvedAt,
      } = person

      const primaryJob = jobs.find(job => job.isPrimary) || jobs[0]
      const primaryOrg = primaryJob?.organization?.name || ''

      res.push({
        id,
        firstName,
        lastName,
        primaryOrg,
        biography,
        email: jobs.find(job => job.isPrimary)?.email,
        linkedIn: personSocialProfiles.find(psp => psp.provider === linkedInKey)?.url,
        approvedAt,
      })
    }
    return res
  }, [])

const PeopleComponent = ({ history }) => (
  <PlatformTable
    columns={PEOPLE_COLUMNS}
    history={history}
    toEntityLabel={selectedPerson => [selectedPerson.firstName, selectedPerson.lastName].join(' ')}
    toRowData={transformRowData}
    type="PERSON"
  />
)
PeopleComponent.propTypes = {
  history: PropTypes.object.isRequired,
}

export default PeopleComponent
