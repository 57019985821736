import { QueryClient } from 'react-query'

export function isClientError(status) {
  return status >= 400 && status < 500
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (_, error) => {
        // avoid retrying errors in 400 range since they are client errors
        return !isClientError(error.response?.status)
      },
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
    },
  },
})

export default queryClient
