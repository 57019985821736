import React from 'react'
import { Button } from '@the-platform-group/component-library/Button'
import { Typography } from '@the-platform-group/component-library'
import { createDomainExcludeRule } from 'services/analytics'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useNotification } from 'components/Notification'
import { useConfirmationDialog } from 'components/ConfirmationDialog'
import { getOrganizationsWithDomains } from 'services/organizations'
import { DataGrid } from '@the-platform-group/component-library/DataGrid'
import usePagination from 'hooks/usePagination'
import { HIDDEN_WEBSITES_QUERY_KEY, HIDABLE_WEBSITES_QUERY_KEY } from './constants'

export const hidableWebsitesTableColumns = [
  {
    field: 'name',
    headerName: 'Organization Name',
    flex: 1,
    minWidth: 200,
  },
  {
    field: 'domain',
    headerName: 'Domain Name',
    flex: 1,
    minWidth: 200,
  },
  {
    field: 'actions',
    headerName: 'Action',
    width: 100,
    renderCell: ({ row }) => (row.domain ? <HideButton domain={row.domain} /> : null),
  },
]

const HideButton = ({ domain }) => {
  const queryClient = useQueryClient()
  const { createNotification } = useNotification()
  const { createConfirmationDialog } = useConfirmationDialog()

  const { mutate: excludeDomainMutate } = useMutation(data => createDomainExcludeRule(data), {
    onSuccess: () => {
      createNotification({ message: 'Website added to hidden list', variant: 'success' })
      queryClient.invalidateQueries([HIDDEN_WEBSITES_QUERY_KEY])
      queryClient.invalidateQueries([HIDABLE_WEBSITES_QUERY_KEY])
    },
    onError: error => {
      createNotification({ message: error.message, variant: 'error' })
    },
  })

  return (
    <Button
      variant="text"
      size="small"
      onClick={() => {
        createConfirmationDialog({
          title: 'Are you sure you want to hide?',
          body: <ModalBody domain={domain} />,
          confirmButtonText: 'Hide',
          onConfirm: () => {
            excludeDomainMutate({ domains: [domain] })
          },
        })
      }}
    >
      Hide
    </Button>
  )
}

const ModalBody = domainObj => {
  const { pagination, handlePageChange, handleRowsChange, pageSizeOptions } = usePagination()

  const params = {
    exclude_nil_types: true,
    include_global_domain_exclude_rules: true,
    with_domain_only: true,
    page_offset: pagination.page * pagination.pageSize,
    page_size: pagination.pageSize,
    domains: [domainObj.domain],
  }

  const { data, isLoading } = useQuery(
    ['organizationsToBeHidden', params.page_offset, params.page_size, domainObj.domain],
    () => getOrganizationsWithDomains(params),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      initialData: { data: [], meta: { item_count: 0 } },
      select: data => ({
        data: data?.data || [],
        meta: {
          ...data?.meta,
          item_count: data?.meta?.item_count || 0,
        },
      }),
    },
  )

  return (
    <>
      <Typography variant="body2">
        Hidden organizations will be excluded from all customer accounts, including their table
        views and summary emails. You can restore them at any time. Organizations may be mapped to
        the same domain, hiding such an organization will remove all organizations with the same
        domain.
      </Typography>
      <Typography variant="body2" sx={{ my: 2 }}>
        Please note: These changes apply globally to all customers, so ensure that you've selected
        the correct organization before proceeding.
      </Typography>
      <Typography variant="body2">
        The following organizations with the same domain will be hidden:
      </Typography>
      <DataGrid
        disableReset={true}
        rows={data?.data}
        rowCount={data?.meta?.item_count}
        columns={[{ field: 'name', headerName: 'Organization Name' }]}
        loading={isLoading}
        name="organizationGrid"
        autoHeight={false}
        sx={{ maxHeight: 400, overflowY: 'auto' }}
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        pageSizeOptions={pageSizeOptions}
        page={pagination.page}
        pageSize={pagination.pageSize}
        onPageSizeChange={handleRowsChange}
        onPageChange={handlePageChange}
      />
    </>
  )
}
