import { DataGrid } from '@the-platform-group/component-library/DataGrid'
import { hidableWebsitesTableColumns } from './utils/hidableWebsiteColumns'
import useHidableWebsiteRows from './hooks/useHidableWebsiteRows'
import usePagination from 'hooks/usePagination'
import { NoMatchesFoundOverlay } from 'components/Search/NoMatchesFound'

const HidableWebsitesTable = ({ orgSearchTerm }) => {
  const { pagination, handlePageChange, handleRowsChange, pageSizeOptions } = usePagination()
  const { rows, totalItemCount, isLoading } = useHidableWebsiteRows(orgSearchTerm, pagination)

  return (
    <DataGrid
      name="hiddenWebsites"
      columns={hidableWebsitesTableColumns}
      rows={rows}
      rowCount={totalItemCount}
      commonColumnProps={{ headerAlign: 'left' }}
      autoHeight
      disableRowSelectionOnClick
      loading={isLoading}
      pageSizeOptions={pageSizeOptions}
      page={pagination.page}
      pageSize={pagination.pageSize}
      onPageSizeChange={handleRowsChange}
      onPageChange={handlePageChange}
      localeText={{ noRowsLabel: 'Search to display organizations' }}
      slots={{
        ...(orgSearchTerm && {
          noRowsOverlay: () => <NoMatchesFoundOverlay term={orgSearchTerm} />,
        }),
      }}
    />
  )
}

export default HidableWebsitesTable
