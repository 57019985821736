import { Button } from '@the-platform-group/component-library/Button'
import { Typography } from '@the-platform-group/component-library'
import { useQueryClient, useMutation } from 'react-query'
import { DataGrid } from '@the-platform-group/component-library/DataGrid'
import { hiddenWebsitesTableColumns } from './utils/hiddenWebsiteColumns'
import useHiddenWebsiteRows from './hooks/useHiddenWebsiteRows'
import usePagination from 'hooks/usePagination'
import { NoMatchesFoundOverlay } from 'components/Search/NoMatchesFound'
import { HIDABLE_WEBSITES_QUERY_KEY, HIDDEN_WEBSITES_QUERY_KEY } from './utils/constants'
import { useNotification } from 'components/Notification'
import { useConfirmationDialog } from 'components/ConfirmationDialog'
import { bulkDeleteDomainExcludeRules } from 'services/analytics'

const HiddenWebsitesTable = ({ orgSearchTerm }) => {
  const { pagination, handlePageChange, handleRowsChange, pageSizeOptions } = usePagination()
  const { rows, totalItemCount, isLoading } = useHiddenWebsiteRows(orgSearchTerm, pagination)

  return (
    <DataGrid
      name="hiddenWebsites"
      getRowId={row => row.organizationPath.join('-')}
      columns={hiddenWebsitesTableColumns}
      rows={rows}
      rowCount={totalItemCount}
      commonColumnProps={{ headerAlign: 'left' }}
      autoHeight
      loading={isLoading}
      pageSizeOptions={pageSizeOptions}
      page={pagination.page}
      pageSize={pagination.pageSize}
      onPageSizeChange={handleRowsChange}
      onPageChange={handlePageChange}
      treeData
      getTreeDataPath={row => row.organizationPath}
      localeText={{ noRowsLabel: 'There are no hidden organizations.' }}
      slots={{
        ...(orgSearchTerm && {
          noRowsOverlay: () => <NoMatchesFoundOverlay term={orgSearchTerm} />,
        }),
      }}
      groupingColDef={{
        headerName: 'Organization Name',
        valueGetter: ({ row }) => row.organizationName,
        hideDescendantCount: true,
        flex: 1,
        minWidth: 300,
      }}
      checkboxSelection
      isRowSelectable={params => params.row.organizationPath.length === 1}
      disableRowSelectionOnClick={false}
      tableActions={[
        {
          key: 'bulk-restore',
          action: ({ selected }) => <BulkRestoreAction selected={selected} />,
        },
      ]}
    />
  )
}

const BulkRestoreAction = ({ selected }) => {
  const queryClient = useQueryClient()
  const { createNotification } = useNotification()
  const { createConfirmationDialog } = useConfirmationDialog()

  const { mutate: bulkRestoreMutate } = useMutation(ids => bulkDeleteDomainExcludeRules(ids), {
    onSuccess: () => {
      createNotification({ message: 'Organizations restored successfully', variant: 'success' })
      queryClient.invalidateQueries([HIDABLE_WEBSITES_QUERY_KEY])
      queryClient.invalidateQueries([HIDDEN_WEBSITES_QUERY_KEY])
    },
    onError: error => {
      createNotification({ message: error.message, variant: 'error' })
    },
  })

  return (
    <Button
      variant="outlined"
      onClick={() => {
        createConfirmationDialog({
          title: 'Restore selected organizations?',
          body: (
            <>
              <Typography variant="body2" sx={{ mb: 2 }}>
                {selected.length} organization(s) will be restored and re-added to all customer
                accounts, including their table views and summary emails.
              </Typography>
              <Typography variant="body2">
                Please note: These changes apply globally to all customers.
              </Typography>
            </>
          ),
          confirmButtonText: 'Restore',
          onConfirm: () => {
            bulkRestoreMutate(selected.map(row => row.domainExcludeRuleId))
          },
        })
      }}
    >
      Restore Selected ({selected.length})
    </Button>
  )
}

export default HiddenWebsitesTable
