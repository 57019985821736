import React from 'react'
import PropTypes from 'prop-types'
// components
import {
  Alert,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@the-platform-group/component-library'
import SOCIAL_PROFILES from 'constants/socialProfiles'
import { Grid } from '@the-platform-group/component-library/Layout'
import ProfilePicture from 'components/ProfilePicture'

const SocialProfilesWrapper = ({ children, person = {}, onProfilePictureToggle }) => {
  if (!person.id) {
    return null
  }

  return (
    <Grid item container direction="column" gap={1}>
      {children}
      <Grid item container direction="column" gap={0.5}>
        <Grid item xs={12}>
          <Typography fontWeight="bold">Profile Image:</Typography>
        </Grid>
        <Grid item container direction="row" alignItems="center" columnGap={4} xs={12}>
          <ProfilePicture person={person} />
          <FormControlLabel
            control={
              <Switch
                defaultChecked={person.setting?.profileImage !== false}
                onChange={({ target }) => onProfilePictureToggle(target.checked)}
              />
            }
            label="Display photo in Irwin app"
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

const PersonSocialProfilesForm = ({
  personSocialProfiles,
  onPersonSocialProfilesChange,
  onProfilePictureToggle,
  person,
  updatePersonSettingsMutation,
}) => {
  const personSocialProfilesList = personSocialProfiles.map((psp, index) => {
    return (
      <TextField
        key={psp.id}
        label={SOCIAL_PROFILES[psp.provider]}
        InputLabelProps={{ htmlFor: 'url' }}
        id={`${psp.provider}Url`}
        name="url"
        value={psp.url || ''}
        onChange={onPersonSocialProfilesChange(index)}
        fullWidth
      />
    )
  })

  const { error: mutationError, reset: resetMutation } = updatePersonSettingsMutation

  return (
    <form>
      <SocialProfilesWrapper person={person} onProfilePictureToggle={onProfilePictureToggle}>
        {personSocialProfilesList}
      </SocialProfilesWrapper>
      {mutationError && (
        <Alert severity="error" onClose={resetMutation} sx={{ mt: 1 }}>
          There was an error in updating photo display settings, please try again.
        </Alert>
      )}
    </form>
  )
}

PersonSocialProfilesForm.propTypes = {
  personSocialProfiles: PropTypes.array.isRequired,
  onPersonSocialProfilesChange: PropTypes.func.isRequired,
}

export default PersonSocialProfilesForm
